import Marquee from "react-fast-marquee";
export const Footer = () => {
  return (
    <>
      <footer className="text-center lg:text-left bg-gray-100 text-gray-600  bottom-0 w-full">
        {/* first section */}
        <div className=" bg-black my-0 ">
          <div className="lg:flex justify-between w-5/5 m-auto">
            <div className="w-full items-center justify-center bg-black text-[#FAE600] lg:text-4xl md:text-4xl py-4 overflow-y-hidden text-l">
              <Marquee
                speed={100}
                gradient={false}
                className="overflow-y-hidden"
              >
                <p className="overflow-y-hidden bgmarquee m-auto">
                  #POPEisDOPE
                </p>
                
                <p className="overflow-y-hidden bgmarquee m-auto">
                  #POPEisDOPE
                </p>


              </Marquee>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
