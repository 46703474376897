import "./App.css";
import { useEffect, useState } from "react";
import { Footer } from "./components/Footer";
import { Loader } from "./components/Loader";
import { Header } from "./components/Header";

import { Roadmap } from "./components/RoadMap";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="App overflow-x-hidden font-Ubuntu">
      <Header />
      {loading && <Loader loading={loading} />}
      <Roadmap />
      <Footer />
    </div>
  );
}

export default App;
