import { FaCreativeCommonsNd } from "react-icons/fa";
import { useState } from "react";
import Fade from "react-reveal/Fade";
import { RxCross2 } from "react-icons/rx";

export const Header = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <>
      <div className="flex justify-between mt-0 py-2 px-6 bg-black">
        <div>
          <img
            decoding="async"
            height="10"
            src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706961359/Pope/POPE_logo_2_hhf579.png"
            data-src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706961359/Pope/POPE_logo_2_hhf579.png"
            className="logo lazyloaded"
            alt="logo"
          />
        </div>

        <div className="py-2">
          <div className=" flex items-center justify-center  ">

            <div className=" lg:flex hidden  space-x-6 text-gray-700">

              {/* Tele */}
              <a
                href="https://t.me/popeonsolana"
                target="_blank"
                rel="noreferrer"
              >
                <div className="border-2 border-black flex items-center justify-center  rounded-full w-12 h-12 p-2">
                  <img
                    alt="img"
                    src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706733920/Pope/telegram-white_mbdh6r.png"
                  />
                </div>
              </a>
              {/* Twitter */}
              <a
                href="https://twitter.com/Popeonsolana"
                target="_blank"
                rel="noreferrer"
              >
                <div className="border-2 border-black flex items-center justify-center  rounded-full w-12 h-12 p-2">
                  <img
                    alt="img"
                    src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706733920/Pope/twitter-white_cp5mb1.png"
                  />
                </div>
              </a>

              {/* </div> */}
            </div>
          </div>
        </div>

        <div
          className="text-6xl lg:hidden text-white cursor-pointer"
          onClick={() => setShowSideBar(true)}
        >
          {!showSideBar && <FaCreativeCommonsNd />}
        </div>
        {showSideBar && (
          <Fade right>
            <div className="w-3/4 bg-[rgba(0,0,0,0.9)] fixed top-0 right-0 h-screen z-50 ">
              <div className=" w-full  text-4xl font-extrabold p-4 text-left  from-neutral-200">
                <div onClick={() => setShowSideBar(false)} className="p-2">
                  <RxCross2 className="text-white cursor-pointer" />
                </div>

                <div className="items-center justify-center text-center">

                  <div className="bg-white text-black mt-4 w-60 p-2 rounded-full">
                    <Fade right>
                      <div className=" flex items-center justify-center  rounded-full w-60 h-12 p-2">
                        <a
                          target="_blank"
                          href="https://twitter.com/Popeonsolana"
                          rel="noreferrer"
                        >
                          Twitter
                        </a>
                      </div>
                    </Fade>
                  </div>

                  <div className="bg-white text-black mt-4 w-60 p-2 rounded-full">
                    <Fade right>
                      <div className=" flex items-center justify-center  rounded-full w-60 h-12 p-2">
                        <a
                          target="_blank"
                          href="https://t.me/popeonsolana"
                          rel="noreferrer"
                        >
                          Telegram
                        </a>
                      </div>
                    </Fade>
                  </div>



                </div>
              </div>
            </div>
          </Fade>
        )}
      </div>
    </>
  );
};
